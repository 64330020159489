<script lang="ts" setup>
import { type HomeSectionList } from '@/utils/types'

const props = defineProps({
  lists: {
    type: Array
  },
  reverse: {
    type: Boolean,
    default: false
  }
})

const lists: Ref<HomeSectionList[]> = ref<HomeSectionList[]>(props.lists as HomeSectionList[])

const currentPic: Ref<any> = ref()

const handleHover = (data: any) => {
  currentPic.value = data.pic?.link
}

const hoverDOMStyle = reactive({
  left: 0,
  top: 0,
  rotate: ''
})

// onMounted(() => {
//   const el = document.querySelector('.section-slide')
//   if (el) {
//     el.addEventListener('mousemove', (data: any) => {
//       let windowWidth: number = window.innerWidth / 2
//       hoverDOMStyle['left'] = data.clientX - 100
//       hoverDOMStyle['top'] = data.clientY - 100
//       if (data.clientX < windowWidth) {
//         hoverDOMStyle['rotate'] = 'rotate-left'
//       } else {
//         hoverDOMStyle['rotate'] = 'rotate-right'
//       }
//     })
//     el.addEventListener('mouseout', () => {
//       hoverDOMStyle['rotate'] = ''
//     })
//   }
// })
const handleMousemove = (data: any) => {
  console.log('首頁跑馬燈', data)
  let windowWidth: number = window.innerWidth / 2
  hoverDOMStyle['left'] = data.clientX - 100
  hoverDOMStyle['top'] = data.clientY - 100
  if (data.clientX < windowWidth) {
    hoverDOMStyle['rotate'] = 'rotate-left'
  } else {
    hoverDOMStyle['rotate'] = 'rotate-right'
  }
}
const handleMouseout = (data: any) => {
  hoverDOMStyle['rotate'] = ''
}
</script>

<template>
  <div @mousemove="handleMousemove" @mouseout="handleMouseout">
    <div class="hover-img" :class="hoverDOMStyle['rotate']"
      :style="{ left: `${hoverDOMStyle['left']}px`, top: `${hoverDOMStyle['top']}px` }">
      <img :src="currentPic" alt="活動圖片" />
    </div>
    <div class="slide-track-outbox" :class="{ reverse: !!props.reverse }">
      <div class="slide-track">
        <div class="slide">
          <div class="slide-list" v-for="(item, index) in lists" :key="index">
            <nuxt-link :to="item.link" class="slide-list__box" @mouseenter="handleHover(item)">
              <div class="slide-list__pic">
                <Nimg :src="item.pic?.link" :alt="item.pic?.alt" :title="item.pic?.title" />
              </div>
              <div class="slide-list__title">{{ item.title }}</div>
            </nuxt-link>
          </div>
        </div>
        <div class="slide">
          <div class="slide-list" v-for="(item, index) in lists" :key="index">
            <nuxt-link :to="item.link" class="slide-list__box" @mouseenter="handleHover(item)"
              @mouseleave="currentPic = ''">
              <div class="slide-list__pic">
                <Nimg :src="item.pic?.link" :alt="item.pic?.alt || item.title" format="webp" />
              </div>
              <div class="slide-list__title">{{ item.title }}</div>
            </nuxt-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@keyframes marquee-horizontal {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(-50%);
  }
}

@keyframes marquee-horizontal-reverse {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(-50%);
  }
}

.hover-img {
  position: fixed;
  z-index: 10;
  width: 100%;
  max-width: toRem(400);
  pointer-events: none;
  transform: scale(0);
  transition: transform 0.8s cubic-bezier(0.87, 0, 0.13, 1);

  @include max-media(990) {
    display: none;
  }

  &.rotate-left {
    transform: scale(1);

    img {
      transform: rotate(-8deg);
    }
  }

  &.rotate-right {
    transform: scale(1);

    img {
      transform: rotate(8deg);
    }
  }

  img {
    display: block;
    width: 100%;
    transition: all 0.4s ease-in-out;
  }
}

.slide-track-outbox {
  &.reverse {
    transform: scaleX(-1);

    .slide {
      transform: scaleX(-1);
    }
  }
}

.slide-track {
  display: flex;
  width: max-content;
  animation: marquee-horizontal 50s linear infinite;

  @include min-media(991) {
    white-space: nowrap;
  }

  &:hover {
    animation-play-state: paused;
  }
}

.slide {
  display: flex;
}

.slide-list {
  &__box {
    display: flex;
    cursor: pointer;

    @include max-media(990) {
      max-width: toRem(340);
      margin-left: toRem(20);
    }
  }

  &__pic {
    display: block;
    width: toRem(80);
    flex-shrink: 0;

    @include min-media(991) {
      display: none;
    }

    @include max-media(990) {
      height: toRem(80);

      img {
        height: 100%;
        object-fit: cover;
      }
    }

    img {
      display: block;
      width: 100%;
    }
  }

  &__title {
    display: flex;
    align-items: center;
    margin-left: toRem(30);
    font-size: toRem(28);
    font-weight: 500;

    @include max-media(990) {
      margin-left: toRem(15);
      font-size: toRem(22);
      line-height: 1.7;
      @include dot-many-line;
    }

    &::before {
      content: '';
      width: toRem(23);
      height: toRem(23);
      margin-right: toRem(30);
      background-color: $color-gray-3;
      flex-shrink: 0;

      @include max-media(990) {
        display: none;
      }
    }
  }
}
</style>
